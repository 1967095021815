exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-charts-base-64-jdk-vs-apache-commons-index-tsx": () => import("./../../../src/pages/charts/base64-jdk-vs-apache-commons/index.tsx" /* webpackChunkName: "component---src-pages-charts-base-64-jdk-vs-apache-commons-index-tsx" */),
  "component---src-pages-charts-base-64-jni-index-tsx": () => import("./../../../src/pages/charts/base64-jni/index.tsx" /* webpackChunkName: "component---src-pages-charts-base-64-jni-index-tsx" */),
  "component---src-pages-charts-java-compression-compression-ratio-tsx": () => import("./../../../src/pages/charts/java-compression/CompressionRatio.tsx" /* webpackChunkName: "component---src-pages-charts-java-compression-compression-ratio-tsx" */),
  "component---src-pages-charts-java-compression-index-tsx": () => import("./../../../src/pages/charts/java-compression/index.tsx" /* webpackChunkName: "component---src-pages-charts-java-compression-index-tsx" */),
  "component---src-pages-charts-java-compression-real-data-tsx": () => import("./../../../src/pages/charts/java-compression/RealData.tsx" /* webpackChunkName: "component---src-pages-charts-java-compression-real-data-tsx" */),
  "component---src-pages-charts-mysql-blob-fetch-index-tsx": () => import("./../../../src/pages/charts/mysql-blob-fetch/index.tsx" /* webpackChunkName: "component---src-pages-charts-mysql-blob-fetch-index-tsx" */),
  "component---src-pages-charts-mysql-streaming-index-js": () => import("./../../../src/pages/charts/mysql-streaming/index.js" /* webpackChunkName: "component---src-pages-charts-mysql-streaming-index-js" */),
  "component---src-pages-charts-native-access-index-tsx": () => import("./../../../src/pages/charts/native-access/index.tsx" /* webpackChunkName: "component---src-pages-charts-native-access-index-tsx" */),
  "component---src-pages-charts-offheap-array-index-tsx": () => import("./../../../src/pages/charts/offheap-array/index.tsx" /* webpackChunkName: "component---src-pages-charts-offheap-array-index-tsx" */),
  "component---src-pages-charts-offheap-hashmap-index-tsx": () => import("./../../../src/pages/charts/offheap-hashmap/index.tsx" /* webpackChunkName: "component---src-pages-charts-offheap-hashmap-index-tsx" */),
  "component---src-pages-charts-read-lines-index-js": () => import("./../../../src/pages/charts/read-lines/index.js" /* webpackChunkName: "component---src-pages-charts-read-lines-index-js" */),
  "component---src-pages-charts-read-utf-8-index-js": () => import("./../../../src/pages/charts/read-utf8/index.js" /* webpackChunkName: "component---src-pages-charts-read-utf-8-index-js" */),
  "component---src-pages-charts-region-matches-index-tsx": () => import("./../../../src/pages/charts/region-matches/index.tsx" /* webpackChunkName: "component---src-pages-charts-region-matches-index-tsx" */),
  "component---src-pages-charts-scala-serialization-2022-index-tsx": () => import("./../../../src/pages/charts/scala-serialization-2022/index.tsx" /* webpackChunkName: "component---src-pages-charts-scala-serialization-2022-index-tsx" */),
  "component---src-pages-charts-scala-serialization-index-js": () => import("./../../../src/pages/charts/scala-serialization/index.js" /* webpackChunkName: "component---src-pages-charts-scala-serialization-index-js" */),
  "component---src-pages-charts-scala-string-format-index-js": () => import("./../../../src/pages/charts/scala-string-format/index.js" /* webpackChunkName: "component---src-pages-charts-scala-string-format-index-js" */),
  "component---src-pages-charts-set-map-java-vs-scala-index-tsx": () => import("./../../../src/pages/charts/set-map-java-vs-scala/index.tsx" /* webpackChunkName: "component---src-pages-charts-set-map-java-vs-scala-index-tsx" */),
  "component---src-pages-charts-version-parsing-index-tsx": () => import("./../../../src/pages/charts/version-parsing/index.tsx" /* webpackChunkName: "component---src-pages-charts-version-parsing-index-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-by-tag-js": () => import("./../../../src/templates/blog-by-tag.js" /* webpackChunkName: "component---src-templates-blog-by-tag-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-static-js": () => import("./../../../src/templates/static.js" /* webpackChunkName: "component---src-templates-static-js" */),
  "component---src-templates-what-i-listen-list-tsx": () => import("./../../../src/templates/what-i-listen/list.tsx" /* webpackChunkName: "component---src-templates-what-i-listen-list-tsx" */),
  "component---src-templates-what-i-listen-single-episode-tsx": () => import("./../../../src/templates/what-i-listen/single-episode.tsx" /* webpackChunkName: "component---src-templates-what-i-listen-single-episode-tsx" */),
  "component---src-templates-what-i-listen-tag-cloud-tsx": () => import("./../../../src/templates/what-i-listen/tag-cloud.tsx" /* webpackChunkName: "component---src-templates-what-i-listen-tag-cloud-tsx" */),
  "component---src-templates-what-i-read-index-js": () => import("./../../../src/templates/what-i-read/index.js" /* webpackChunkName: "component---src-templates-what-i-read-index-js" */)
}

